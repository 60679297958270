.background {
  background-color: #00000044;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pop-up {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F0F0F0;
  width: 300px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 25px #00000053;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif; /* desired font and backup fonts */
}

.pop-up h1, .pop-up h2, .pop-up h3 {
  text-align: center;
  margin: 10;
}

.pop-up h1 { font-size: 30px; }
.pop-up h2 { font-size: 15px; }

.pop-up h3 {
  font-size: 20px; 
  margin: 0;
}

.pop-up:focus {
  outline: none;
}

.options {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px;
}

.button {
  margin: 10px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  background-color: #D0D0D0;
  transition: background-color 0.3s ease;
  cursor: pointer; /* Change cursor on hover */
}

.button.hovered {
  background-color: #002e5d;
  color: #FFFFFF;
}