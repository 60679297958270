.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.title-text h3 {
  margin: 0;
}

.add-item button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px;
}

.add-item button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.required {
  color: red;
  margin-left: 2px; /* Adjust the spacing as needed */
}

.main-container {
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
}
