.page {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  height: 70vh;          /* Optional: Full viewport height */
}

.loadingBox {
  width: 200px;
  height: 180px;
  border-radius: 10px;

  background-color: #E0E0E0;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin: 15px;
  color: #002e5d;
}

.message {
  margin: 10px;
  font-size: 22px; /* Adjust font size as needed */
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif;
}