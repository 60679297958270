.byu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0px 10px 6px;
  height: 70px;

  background-color: #002e5d; /* BYU blue */
  color: #ffffff; /* White text */
}

.byu-official { /* container for left aligned elements */
  display: flex;
  align-items: center;
  padding: 10px 10px;
  height: 95%;
  justify-content: space-between; /* aligns items to the left*/
}

.byu-separator {
  width: 1px; /* Width of the white separator bar */
  background-color: #ffffff5d; /* White color */
  height: 165%; /* 85% of the parent height */
  /* height: 100px; */
  top: 2.5%; /* Position the separator vertically in the center */
  margin-left: 15px; /* Adjust the left position as needed */
  margin-right: 20px; /* Adjust the left position as needed */
}

.byu-logo svg {
  padding: 1px 4px 2px 4px;
  width: 90px; /* Adjust logo size as needed */
  cursor: pointer;
}

.byu-title h2 {
  margin: 0;
  font-size: 22px; /* Adjust font size as needed */
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif; /* desired font and backup fonts */
}

.menu {
  display: flex;
  align-items: center;
}

.name-box {
  margin: 0;
  margin-right: 6px;
  font-size: 18px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  cursor: pointer;
}

.user-name {
  text-align: right;
  align-items: center;
  margin: 0;
  font-size: 18px;
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif; /* desired font and backup fonts */
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  padding: 18px;
  padding-right: 0;
}

.user-name.hovered {
  transition: color 0.2s ease;
  color: #ffffffca
}

.svg-icon {
  display: flex;
  align-items: center;
  width: 34px;
  height: 34px;
  fill: None;
}

.stroke-path {
  stroke: #ffffff;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill-rule: evenodd;
  clip-rule: evenodd;
  transition: stroke 0.2s ease;
}

.stroke-path.hovered {
  stroke: #ffffffca;
}

.svg-box {
  padding: 18px;
  padding-right: 24px;
  cursor: pointer;
}