button.project-button {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  height: 300px; /* Adjust height as needed */
  background-color: #ffffff; /* White background */
  border: 2px solid #cccccc; /* Gray border */
  border-radius: 10px; /* Rounded corners */
  color: black;
}

button.project-button:hover {
  background-color: #ffffff; /* White background on hover */
  color:black;
}

button.misc-button {
  background-color: #ffffff; /* White background */
  border: 2px solid #cccccc; /* Gray border */
  border-radius: 5px; /* Rounded corners */
  color: black;
}

.project-image {
  max-width: 250px; /* Adjust image size */
  margin-bottom: 10px; /* Spacing between image and text */
}

.project-text {
  font-size: 18px; /* Adjust font size */
  font-weight: bold
}