body {
  overflow-x: hidden; /* Prevents horizontal scrolling */
  margin: 0; /* Remove default body margin */
}

.title-text h2 {
    font-size: 34px;
    font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif;
    font-weight: 900;
    color: #002e5d;
  }

.title-text h3 {
  font-size: 28px;
  font-family: 'HCo Ringside Narrow SSm', 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #002e5d;
}